import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import * as Yup from "yup";

import { Button, Typography, Modal } from "stories/components";
import { Container, Row, Col } from "stories/layout";

import { useNotification } from "stories/components/Notification";
import { httpUpdateUser } from "services/users";
import { httpGetEnums } from "services/enums";
import { httpGetEvents } from "services/events";
import { useAuthContext } from "contexts/AuthContext";

import Select from "react-select";
import { useLocation } from "react-router";
import LoaderScreen from "components/LoaderScreen";

import warning from "assets/img/icons/common/circle-warning.svg";
import stripeIcon from "assets/img/icons/common/stripe.png";
import check from "assets/img/icons/common/check-circle-regular.svg";
import { CircleSpinner, CircleSpinnerOverlay } from "react-spinner-overlay";

import appConfig from "services/config";

const baseUrl = appConfig.apiUrl;

const MyAccountPayment = (props) => {
  const {
    selected45minPrice,
    setSelected45minPrice,
    selected90minPrice,
    setSelected90minPrice,
    min45PriceList,
    min90PriceList,
    myUser,
  } = props;

  const { errors, handleSubmit, isSubmitting, t } = props;

  const location = useLocation();
  const [registerModal, setRegisrerModal] = useState(false);
  const [returnModal, setReturnModal] = useState(false);
  const [link, setLink] = useState("");
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const referrer = document.referrer;

  useEffect(() => {
    console.log("referrer url", referrer);
  }, [referrer]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `jwt ${localStorage.getItem("accessToken")}`,
      },
    };

    let path = location?.pathname;

    if (path.includes("return")) {
      let token = location?.search.split("token=");
      fetch(
        `${baseUrl}/users/me/complete_stripe_account_connection/?token=${token[1]}`,
        requestOptions
      ).then((res) => {
        console.log("Response", res);
        console.log("Response data", res.data);
        if (res?.status === 200) {
          setCompleted(true);
          setRegisrerModal(false);
          toggleReturnModal();
        } else {
          toggleRegister();
          // setRegisrerModal(false)
          // toggleReturnModal()
        }
      });
    }
  }, [location]);

  const min45PriceHandler = (event) => {
    setSelected45minPrice(event);
    // console.log('45minPriceHandler:', event)
  };

  const min90PriceHandler = (event) => {
    setSelected90minPrice(event);
    // console.log('90minPriceHandler:', event)
  };

  const toggleRegister = () => {
    setLoader(true);
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `jwt ${localStorage.getItem("accessToken")}`,
      },
    };

    fetch(`${baseUrl}/users/me/stripe_create_account/`, requestOptions)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Bad server response");
        }
        return res.json();
      })
      .then((data) => {
        window.location.href = data.account_link;
      });
  };

  const toggleRegisterModal = () => {
    setRegisrerModal(!registerModal);
  };

  const toggleResetStripe = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `jwt ${localStorage.getItem("accessToken")}`,
      },
    };
    fetch(`${baseUrl}/users/me/reset_stripe_account/`, requestOptions).then(
      (res) => {
        if (res.status === 200) {
          window.location.reload();
        } else {
          props.notify({ title: t("Virhe"), message: t("Kokeile uudestaan.") });
        }
      }
    );
  };

  const toggleReturnModal = () => {
    setLoader(false);
    if (completed) {
      setReturnModal(!returnModal);
      // window.location.reload()
      window.location.href = "/dashboard/myaccount/payment/";
    }
    setReturnModal(!returnModal);
  };

  const toggleHelp = () => {
    window.open("https://www.terapeuttini.fi/ohjeet");
  };

  // useEffect(() => {
  //   console.log("myUser", myUser?.stripe_user_id)
  //   console.log("link", link)
  // },[myUser, link])

  if (loading) {
    return (
      <Container fluid className="saoy_min_height">
        <LoaderScreen />
      </Container>
    );
  }

  return (
    <>
      <Container className="mt-5">
        <Row>
          <Col>
            <Row className="w-100">
              <Col>
                <Typography variant={"h1"}>Stripe maksupalvelu</Typography>
              </Col>
            </Row>
            <Row className="mt-3 w-50">
              <Col>
                {myUser?.stripe_account_completed === null ? (
                  <Button onClick={toggleRegisterModal}>
                    <Typography variant={"h4"}>
                      Luo kauppiastunnukset
                    </Typography>
                  </Button>
                ) : (
                  <div className="d-flex" style={{ pointerEvents: "none" }}>
                    <img src={check} alt="verified" height="50px" />
                    <Typography className="ml-3" variant={"h4"}>
                      Palveluntarjoajatilisi on hyväksytty!
                    </Typography>
                  </div>
                )}
              </Col>
            </Row>
            <Row className="mt-3 w-50">
              <Col>
                {myUser?.stripe_account_completed === null && (
                  <Button onClick={toggleHelp}>
                    <Typography variant={"h5"}>Näytä ohjeet</Typography>
                  </Button>
                )}
              </Col>
            </Row>

            <Row className="mt-3 w-50">
              <Col>
                {myUser?.stripe_user_id !== null &&
                myUser?.stripe_account_completed === null ? (
                  <Button onClick={toggleResetStripe}>
                    <Typography variant={"h4"}>Resetoi tiedot</Typography>
                  </Button>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row className="mt-5 w-50">
              <Col>
                <Typography variant={"h1"}>Hinnasto</Typography>
              </Col>
            </Row>
            <Row className="mt-3 w-50">
              <Col xs="8" sm="6" xl="3">
                <Typography bold={true}>45 min hinta</Typography>
                <Select
                  isDisabled={myUser?.stripe_account_completed === null}
                  placeholder={"Valitse hinta"}
                  options={min45PriceList}
                  value={selected45minPrice}
                  onChange={min45PriceHandler}
                  getOptionLabel={(option) => `${option["value"]} €`}
                />
              </Col>
            </Row>
            <Row className="mt-4 w-50">
              <Col xs="8" sm="6" xl="3">
                <Typography bold={true}>90 min hinta</Typography>
                <Select
                  isDisabled={myUser?.stripe_account_completed === null}
                  placeholder={"Valitse hinta"}
                  options={min90PriceList}
                  value={selected90minPrice}
                  onChange={min90PriceHandler}
                  getOptionLabel={(option) => `${option["value"]} €`}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col className="my-3">
                <Button
                  variant="success"
                  disabled={isSubmitting}
                  onClick={() => handleSubmit()}
                >
                  Tallenna
                </Button>
                {Boolean(errors["general"]) && (
                  <Typography className="text-danger">
                    {errors["general"]}
                  </Typography>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {/* REGISTERING for stripe */}
      <Modal isOpen={registerModal} toggle={toggleRegisterModal} centered>
        <div className="modal-header p-0 mb-3">
          <Typography className="text-black" variant="h3" bold>
            Aloita rekisteröinti STRIPE maksupalveluun.
          </Typography>
        </div>
        <div className="modal-body p-0">
          <Row>
            <Col>
              <Row className="mt-2">
                <Col>
                  <Typography variant={"display5"}>
                    Maksujen vastoonotto vaatii, että rekisröidyt Stripe
                    maksunvälityspalveluun.
                  </Typography>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col
                  className="d-flex justify-content-center"
                  style={{ minHeight: "200px" }}
                >
                  {loader ? (
                    ""
                  ) : (
                    <img src={stripeIcon} alt="stripe" height={"200px"} />
                  )}
                  <CircleSpinner
                    loading={loader}
                    size={100}
                    color="#1F63E1"
                    outerBorderOpacity="0.3"
                    innerBorderWidth={10}
                    outerBorderWidth={10}
                  />
                  <CircleSpinnerOverlay loading={loader} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="modal-footer p-0 mt-5 d-flex justify-content-between">
          <Button
            color="light"
            onClick={toggleRegisterModal}
            style={{ width: "180px", height: "50px" }}
          >
            <Typography className="text-white" bold>
              Peruuta
            </Typography>
          </Button>

          <Button
            style={{ height: "50px" }}
            color="success"
            // disabled={link.length === 0 || link === null}
            onClick={toggleRegister}
          >
            {/* <a href={link} target="_self"> */}
            <Typography className="text-white" bold>
              Aloita rekisteröinti
            </Typography>
            {/* </a> */}
          </Button>
        </div>
      </Modal>

      {/* RETURN FROM STRIPE */}
      <Modal isOpen={returnModal} toggle={toggleReturnModal} centered>
        <div className="modal-header p-0 mb-3">
          <Typography className="text-black" variant="h3" bold>
            Stripe maksupalvelu
          </Typography>
        </div>
        <div className="modal-body p-0">
          <Row>
            <Col>
              <Row className="mt-2">
                <Col>
                  {completed === true ? (
                    <Typography variant={"display5"}>
                      Rekisteröinti onnistui!
                    </Typography>
                  ) : (
                    <Typography variant={"display5"}>
                      Tililläsi on puuttuvia tai virheellisiä tietoja. Lisää
                      tiedot, jotta tilisi voidaan hyväksyä.
                    </Typography>
                  )}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col></Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="modal-footer p-0 mt-5 d-flex justify-content-between">
          <Button
            color="default"
            onClick={toggleReturnModal}
            style={{ width: "180px" }}
          >
            <Typography bold>Valmis</Typography>
          </Button>
        </div>
      </Modal>
    </>
  );
};

const defaultValues = {
  id: null,
  name: "",
};

const MyAccountPaymentFormik = withFormik({
  validateOnMount: true,
  mapPropsToValues: (props) => {
    const { preSetValues } = props;
    if (preSetValues) {
      return preSetValues;
    } else {
      return defaultValues;
    }
  },
  validationSchema: (props) => {
    const { t } = props;
    const required = t("Kenttä on pakollinen");
    return Yup.object().shape({});
  },
  handleSubmit: (values, { setSubmitting, setErrors, props }) => {
    const { t, selected45minPrice, selected90minPrice } = props;

    let min45Price = selected45minPrice.value;

    let min90Price = selected90minPrice.value;

    let { profile_image, ...rest } = values;

    let data = {
      ...rest,
      min_45_price: min45Price,
      min_90_price: min90Price,
    };

    httpUpdateUser("me", data).then(
      (res) => {
        setSubmitting(false);
        props.notify({
          title: t("Käyttäjä"),
          message: t("Päivitetty onnistuneesti"),
        });
      },
      (error) => {
        setSubmitting(false);
        if (error?.data?.detail) {
          setErrors({
            general: error?.data?.detail,
          });
        } else {
          setErrors({
            general: t(
              "Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen."
            ),
          });
        }
      }
    );
  },
  displayName: "BasicForm",
})(MyAccountPayment);

const MyAccountPaymentView = ({ match, history }) => {
  // API requests here
  const { t } = useTranslation();
  const { myUser, getMyUser } = useAuthContext();
  const { isAuthenticated } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [selected45minPrice, setSelected45minPrice] = useState();
  const [selected90minPrice, setSelected90minPrice] = useState();
  const [min45PriceList, setMin45PriceList] = useState([]);
  const [min90PriceList, setMin90PriceList] = useState([]);
  const [events, setEvents] = useState([]);

  // useEffect(() => {
  //   httpGetEnums().then(res => {
  //     setMin45PriceList(res.data.users.min45_price)
  //     setMin90PriceList(res.data.users.min90_price)
  //   })
  // },[])

  useEffect(() => {
    console.log("isAuthenticated", isAuthenticated);

    if (isAuthenticated === true) {
      setLoading(true);
      if (myUser?.min_45_price === null) {
      } else {
        setSelected45minPrice({ value: myUser?.min_45_price });
      }

      if (myUser?.min_90_price === null) {
      } else {
        setSelected90minPrice({ value: myUser?.min_90_price });
      }

      httpGetEnums().then((res) => {
        setMin45PriceList(res.data.users.min45_price);
        setMin90PriceList(res.data.users.min90_price);
      });

      httpGetEvents({ user: myUser?.id }).then((res) => {
        setEvents(res?.data);
        setLoading(false);
      });
    } else {
      window.location.replace("/auth/login");
    }
  }, []);

  // useEffect(() => {
  //   setLoading(true)
  //   httpGetEvents({user:myUser?.id}).then(res => {
  //     setEvents(res?.data)
  //     setLoading(false)
  //    })
  // },[])

  const handleChangePassword = () => {
    history.push("/dashboard/myaccount/password");
  };

  const clickMyAccountViewHandler = () => {
    history.push(`/dashboard/myaccount/`);
  };

  const clickCalendarViewHandler = () => {
    history.push(`/dashboard/myaccount/calendar/`);
  };

  const clickPaymentViewHandler = () => {
    history.push(`/dashboard/myaccount/payment/`);
  };

  const clickEventsViewHandler = () => {
    history.push(`/dashboard/myaccount/events/`);
  };

  if (loading) {
    return (
      <Container fluid className="saoy_min_height">
        <LoaderScreen />
      </Container>
    );
  }

  return (
    <Container className="my-4">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/dashboard/frontpage">
              <b>Etusivu</b>
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("Omat tiedot")} / {t("Maksuasetukset")}
          </li>
        </ol>
      </nav>

      <div className="d-flex justify-content-between">
        <div>
          <Row>
            <Col>
              <Button
                className="header_btn_without_border"
                variant={"secondary"}
                onClick={clickMyAccountViewHandler}
              >
                {myUser?.business_id ? (
                  <Typography className="text-dark" variant={"h3"}>
                    Perustiedot
                  </Typography>
                ) : (
                  <Typography
                    className="d-inline-flex text-dark"
                    inline
                    variant={"h3"}
                  >
                    Perustiedot&nbsp;
                    <img src={warning} alt="important" />
                  </Typography>
                )}
              </Button>
            </Col>
            <Col>
              <Button
                className="header_btn_without_border"
                variant={"secondary"}
                onClick={clickCalendarViewHandler}
              >
                {events.length > 0 ? (
                  <Typography className="text-dark" variant={"h3"}>
                    Kalenteri
                  </Typography>
                ) : (
                  <Typography
                    className="d-inline-flex text-dark"
                    inline
                    variant={"h3"}
                  >
                    Kalenteri&nbsp;
                    <img src={warning} alt="important" />
                  </Typography>
                )}
              </Button>
            </Col>
            <Col>
              <Button
                className="header_btn_without_border"
                variant={"secondary"}
                onClick={clickPaymentViewHandler}
              >
                {myUser?.stripe_account_completed ? (
                  <Typography className="active_color" inline variant={"h3"}>
                    Maksuasetukset
                  </Typography>
                ) : (
                  <Typography
                    className="active_color d-inline-flex"
                    inline
                    variant={"h3"}
                  >
                    Maksuasetukset&nbsp;
                    <img src={warning} alt="important" />
                  </Typography>
                )}
              </Button>
            </Col>
            <Col>
              <Button
                className="header_btn_without_border"
                variant={"secondary"}
                onClick={clickEventsViewHandler}
              >
                <Typography className="text-dark" variant={"h3"}>
                  Tapahtumat
                </Typography>
              </Button>
            </Col>
          </Row>
        </div>
        <div>
          <Button onClick={handleChangePassword}>{t("Vaihda salasana")}</Button>
        </div>
      </div>
      <MyAccountPaymentFormik
        history={history}
        t={t}
        myUser={myUser}
        notify={notify}
        selected45minPrice={selected45minPrice}
        setSelected45minPrice={setSelected45minPrice}
        selected90minPrice={selected90minPrice}
        setSelected90minPrice={setSelected90minPrice}
        min45PriceList={min45PriceList}
        setMin45PriceList={setMin45PriceList}
        min90PriceList={min90PriceList}
        setMin90PriceList={setMin90PriceList}
      />
    </Container>
  );
};

export default MyAccountPaymentView;
